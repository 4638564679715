$icon-font-path : "/fonts/bootstrap/";
$fa-font-path : "/fonts/font-awesome/";

@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome-sass/assets/stylesheets/font-awesome";

.breadcrumb > li + li:before {
    content: "/\00a0";
}

/****** LOGIN ********/
html.login{
    height: 100%;
    body {
        height: 100%;
        background-color: #ededed;
        .panel-login {
            margin-top: 40%;
        }
    }
}


/******* BOOTSTRAP OVERRIDING ******/
.navbar-brand {
    > img {
        display: inline;
        max-height: $navbar-height;
        margin-top: -$navbar-padding-vertical;
    }
}

/******* BOOTSTRAP EXTENDING ******/
.btn-menu {
    padding-top: 4em;
    min-height: 10em;
    margin: 15px 0px;
}